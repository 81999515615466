export default [
  {
    path: '/user',
    name: 'user',
    meta: {
      icon: 'md-albums',
      title: '系统管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'group',
        name: 'group_management',
        meta: {
          icon: 'ios-people',
          title: '机构管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/group/index')
      },
      {
        path: 'user',
        name: 'user_management',
        meta: {
          icon: 'ios-person',
          title: '用户管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/user/index')
      },
      {
        path: 'menu',
        name: 'menu_management',
        meta: {
          icon: 'ios-grid',
          title: '菜单管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/menu/index')
      },
      {
        path: 'role',
        name: 'role_management',
        meta: {
          icon: 'ios-bowtie',
          title: '角色管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/role/index')
      },
      {
        path: 'area',
        name: 'area_management',
        meta: {
          icon: 'ios-jet',
          title: '地区表管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/area/index')
      },
      {
        path: 'jobInfo',
        name: 'jobInfo_management',
        meta: {
          icon: 'ios-clock',
          title: '调度任务',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/jobInfo/index')
      }
    ]
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      icon: 'md-albums',
      title: '商家管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'shop',
        name: 'shop_management',
        meta: {
          icon: 'ios-people',
          title: '商家管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/shop/index')
      },
      {
        path: 'shopRoom',
        name: 'shopRoom_management',
        meta: {
          icon: 'ios-people',
          title: '房间管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: true
        },
        component: () => import('@/views/shopRoom/index')
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      icon: 'md-albums',
      title: '订单管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [

      {
        path: 'roomOrder',
        name: 'roomOrder_management',
        meta: {
          icon: 'ios-people',
          title: '房间预约订单',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/roomOrder/index')
      },
      {
        path: 'activityOrder',
        name: 'activityOrder_management',
        meta: {
          icon: 'ios-people',
          title: '充值活动订单',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/activityOrder/index')
      },
    ]
  },
  {
    path: '/coupon',
    name: 'coupon',
    meta: {
      icon: 'md-albums',
      title: '优惠券管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'coupon_management',
        meta: {
          icon: 'md-card',
          title: '优惠券管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/coupon/index')
      }
    ]
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      icon: 'md-albums',
      title: '充值活动管理',
      notCache: true
    },
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'activity_management',
        meta: {
          icon: 'ios-people',
          title: '充值活动管理',
          notCache: true,
          hideInBread: false,
          hideInMenu: false
        },
        component: () => import('@/views/activity/index')
      }
    ]
  }
]
