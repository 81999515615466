import { createI18n } from 'vue-i18n'
import store from '@/store'
import customZhCn from './lang/zh-CN'
import customZhTw from './lang/zh-TW'
import customEnUs from './lang/en-US'
import zhCnLocale from 'view-ui-plus/src/locale/lang/zh-CN'
import enUsLocale from 'view-ui-plus/src/locale/lang/en-US'
import zhTwLocale from 'view-ui-plus/src/locale/lang/zh-TW'
const messages = {
  'zh-CN': Object.assign(zhCnLocale, customZhCn),
  'zh-TW': Object.assign(zhTwLocale, customZhTw),
  'en-US': Object.assign(enUsLocale, customEnUs)
}
const lang = store.getters['app/getLocal']
const i18n = createI18n({
  // 使用 Composition API 模式，则需要将其设置为false
  legacy: false,
  // 全局注入 $t 函数
  globalInjection: true,
  locale: lang,
  messages
})

export default i18n
