<template>
  <i v-if="isCustomer" :class="`ivu-icon iconfont ${type}`" :style="styles"></i>
  <i v-else :class="`ivu-icon ivu-icon-${type}`" :style="styles"></i>
</template>
<script setup>
import { computed } from '@vue/runtime-core'
const props = defineProps({
  type: {
    type: String,
    required: true
  },
  color: String,
  size: {
    type: Number,
    default: 16
  }
})
const isCustomer = computed(() => {
  return props.type.indexOf('i-') !== -1
})
const styles = computed(() => {
  return {
    fontSize: `${props.size}px`,
    color: props.color
  }
})
</script>
