// 此处不要导入 @/i18n 使用 i18n.global ，因为我们在 router 中 layout 不是按需加载，所以会在 Guide 会在 I18n 初始化完成之前被直接调用。导致 i18n 为 undefined
const steps = (i18n) => {
  return [
    {
      element: '#guide-start',
      popover: {
        title: i18n.t('guideTitle'),
        description: i18n.t('guideDesc')
      }
    },
    {
      element: '#guide-hamburger',
      popover: {
        title: i18n.t('hamburgerTitle'),
        description: i18n.t('hamburgerDesc')
      }
    },
    {
      element: '#guide-breadcrumb',
      popover: {
        title: i18n.t('breadcrumbTitle'),
        description: i18n.t('breadcrumbDesc')
      }
    },
    {
      element: '#guide-search',
      popover: {
        title: i18n.t('searchTitle'),
        description: i18n.t('searchDesc'),
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-full',
      popover: {
        title: i18n.t('fullTitle'),
        description: i18n.t('fullDesc'),
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-theme',
      popover: {
        title: i18n.t('themeTitle'),
        description: i18n.t('themeDesc'),
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-lang',
      popover: {
        title: i18n.t('langTitle'),
        description: i18n.t('langDesc'),
        position: 'bottom-right'
      }
    },
    {
      element: '#guide-tags',
      popover: {
        title: i18n.t('tagTitle'),
        description: i18n.t('tagDesc')
      }
    },
    {
      element: '#guide-sidebar',
      popover: {
        title: i18n.t('sidebarTitle'),
        description: i18n.t('sidebarDesc'),
        position: 'right-center'
      }
    }
  ]
}
export default steps
