import { showTitle as st } from '@/libs/util'

export const showTitle = (item) => {
  return st(item, this)
}
export const showChildren = (item) => {
  return (
    item.children &&
    (item.children.length > 1 || (item.meta && item.meta.showAlways))
  )
}
export const getNameOrHref = (item, children0) => {
  return item.href
    ? `isTurnByHref_${item.href}`
    : children0
      ? item.children[0].name
      : item.name
}
