<template>
  <div>
    <Modal
      v-model="passwordOpt.isShow"
      title="修改密码"
      :closable="false"
      :mask-closable="false"
    >
      <Form
        :model="localItem"
        :label-width="80"
        :rules="rules"
        ref="tableForm"
        @keydown.enter="ok"
      >
        <FormItem class="item" label="用户名称" prop="username">
          <Input prefix="md-person" placeholder="请输入用户名称"></Input>
        </FormItem>
        <FormItem label="旧密码" prop="password">
          <Input
            autocomplete="new-password"
            prefix="md-key"
            v-model="localItem.password"
            placeholder="请输入旧密码"
            type="password"
          ></Input>
        </FormItem>
        <FormItem label="新密码" prop="newPassword">
          <Input
            autocomplete="new-password"
            prefix="md-key"
            v-model="localItem.newPassword"
            placeholder="请输入新密码"
            type="password"
          ></Input>
        </FormItem>
        <FormItem label="确认密码" prop="newPasswordConfirm">
          <Input
            autocomplete="new-password"
            prefix="md-key"
            v-model="localItem.newPasswordConfirm"
            placeholder="请输入确认密码"
            type="password"
          ></Input>
        </FormItem>
      </Form>
      <template #footer>
        <Button type="text" @click="cancel" :disabled="loading">取消</Button>
        <Button type="primary" :loading="loading" @click="ok">确认</Button>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { userDispatch } from '@/api/custom'
import { httpStatus } from '@/libs/http-status'
import { Message } from 'view-ui-plus'
import { nextTick, reactive, ref, toRefs } from 'vue'
const props = defineProps({
  passwordOpt: {
    type: Object,
    default: () => {
      return { isShow: false }
    }
  }
})
const passworCheck = (rule, value, callback, vm) => {
  userDispatch.getAndJoint('checkPassword', value).then((res) => {
    if (res.code !== httpStatus.OK.key) {
      // 校验失败
      callback(new Error('密码与旧密码不匹配'))
    } else {
      // 校验通过
      callback()
    }
  })
}
const newPasswordCheck = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入新密码'))
  } else {
    if (value === data.localItem.password) {
      callback(new Error('新密码与老密码一致'))
    } else if (value.length < 6) {
      callback(new Error('密码长度必须大于等于6'))
    } else {
      callback()
    }
  }
}
const newPasswordConfirmCheck = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请重复新密码'))
  } else {
    if (value === data.localItem.newPassword) {
      callback()
    } else if (value.length < 6) {
      callback(new Error('密码长度必须大于等于6'))
    } else {
      callback(new Error('两次密码输入不一致'))
    }
  }
}
const data = reactive({
  loading: false,
  localItem: {
    password: '',
    newPassword: '',
    newPasswordConfirm: ''
  },
  rules: {
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          passworCheck(rule, value, callback, this)
        },
        trigger: 'blur'
      }
    ],
    newPassword: [
      {
        required: true,
        message: '请输入新密码',
        trigger: 'blur'
      },
      {
        validator: (rule, value, callback) => {
          newPasswordCheck(rule, value, callback, this)
        },
        trigger: 'blur'
      }
    ],
    newPasswordConfirm: [
      {
        required: true,
        message: '请输入确认密码',
        trigger: 'blur'
      },
      {
        validator: (rule, value, callback) => {
          newPasswordConfirmCheck(rule, value, callback, this)
        },
        trigger: 'blur'
      }
    ]
  }
})
const tableForm = ref(null)
const ok = () => {
  tableForm.value.validate(async (valid) => {
    if (valid) {
      nextTick(() => (data.loading = true))
      userDispatch.put('updatePassword', data.localItem).then((res) => {
        if (res.code === httpStatus.OK.key) {
          Message.info('修改密码成功')
          props.passwordOpt.handleLogout()
        } else {
          Message.error(res.msg)
        }
      })
    } else {
      Message.error('请检查输入数据')
    }
  })
}
const cancel = () => {
  Message.info('取消编辑!')
  props.passwordOpt.isShow = false
  tableForm.value.resetFields()
}
const { loading, localItem, rules } = toRefs(data)
</script>
<style lang="scss" scoped>
.item {
  display: none;
}
</style>
