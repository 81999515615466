import { areaDispatch } from '@/api/custom'
// 分类列表
export function categoryList() {
  return {
    url: areaDispatch.url.getAreaList,
    type: 'get',
    data: {
      code: 200,
      body: [
        {
          id: '1',
          areaId: '110000',
          areaName: '北京市',
          chinesePinyin: 'beijingshi',
          pinyinInitials: 'BJS',
          parentId: '0',
          longitude: 116.407387,
          latitude: 39.904179,
          createTime: '2022-07-05 14:25:36',
          updateTime: '2022-07-05 14:25:36',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '19',
          areaId: '120000',
          areaName: '天津市',
          chinesePinyin: 'tianjinshi',
          pinyinInitials: 'TJS',
          parentId: '0',
          longitude: 117.201509,
          latitude: 39.085318,
          createTime: '2022-07-05 14:25:37',
          updateTime: '2022-07-05 14:25:37',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '37',
          areaId: '130000',
          areaName: '河北省',
          chinesePinyin: 'hebeisheng',
          pinyinInitials: 'HBS',
          parentId: '0',
          longitude: 114.530399,
          latitude: 38.037707,
          createTime: '2022-07-05 14:25:37',
          updateTime: '2022-07-05 14:25:37',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '216',
          areaId: '140000',
          areaName: '山西省',
          chinesePinyin: 'shanxisheng',
          pinyinInitials: 'SXS',
          parentId: '0',
          longitude: 112.578781,
          latitude: 37.813948,
          createTime: '2022-07-05 14:25:41',
          updateTime: '2022-07-05 14:25:41',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '345',
          areaId: '150000',
          areaName: '内蒙古自治区',
          chinesePinyin: 'neimengguzizhiqu',
          pinyinInitials: 'NMGZZQ',
          parentId: '0',
          longitude: 111.765226,
          latitude: 40.818233,
          createTime: '2022-07-05 14:25:46',
          updateTime: '2022-07-05 14:25:46',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '461',
          areaId: '210000',
          areaName: '辽宁省',
          chinesePinyin: 'liaoningsheng',
          pinyinInitials: 'LNS',
          parentId: '0',
          longitude: 123.435093,
          latitude: 41.836743,
          createTime: '2022-07-05 14:25:48',
          updateTime: '2022-07-05 14:25:48',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '576',
          areaId: '220000',
          areaName: '吉林省',
          chinesePinyin: 'jilinsheng',
          pinyinInitials: 'JLS',
          parentId: '0',
          longitude: 125.325802,
          latitude: 43.896942,
          createTime: '2022-07-05 14:25:52',
          updateTime: '2022-07-05 14:25:52',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '646',
          areaId: '230000',
          areaName: '黑龙江省',
          chinesePinyin: 'heilongjiangsheng',
          pinyinInitials: 'HLJS',
          parentId: '0',
          longitude: 126.661998,
          latitude: 45.742253,
          createTime: '2022-07-05 14:25:54',
          updateTime: '2022-07-05 14:25:54',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '782',
          areaId: '310000',
          areaName: '上海市',
          chinesePinyin: 'shanghaishi',
          pinyinInitials: 'SHS',
          parentId: '0',
          longitude: 121.473667,
          latitude: 31.230525,
          createTime: '2022-07-05 14:25:57',
          updateTime: '2022-07-05 14:25:57',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '800',
          areaId: '320000',
          areaName: '江苏省',
          chinesePinyin: 'jiangsusheng',
          pinyinInitials: 'JSS',
          parentId: '0',
          longitude: 118.763563,
          latitude: 32.061377,
          createTime: '2022-07-05 14:25:58',
          updateTime: '2022-07-05 14:25:58',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '909',
          areaId: '330000',
          areaName: '浙江省',
          chinesePinyin: 'zhejiangsheng',
          pinyinInitials: 'ZJS',
          parentId: '0',
          longitude: 120.152575,
          latitude: 30.266619,
          createTime: '2022-07-05 14:26:02',
          updateTime: '2022-07-05 14:26:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1011',
          areaId: '340000',
          areaName: '安徽省',
          chinesePinyin: 'anhuisheng',
          pinyinInitials: 'AHS',
          parentId: '0',
          longitude: 117.330139,
          latitude: 31.734559,
          createTime: '2022-07-05 14:26:03',
          updateTime: '2022-07-05 14:26:03',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1132',
          areaId: '350000',
          areaName: '福建省',
          chinesePinyin: 'fujiansheng',
          pinyinInitials: 'FJS',
          parentId: '0',
          longitude: 119.296194,
          latitude: 26.101082,
          createTime: '2022-07-05 14:26:07',
          updateTime: '2022-07-05 14:26:07',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1226',
          areaId: '360000',
          areaName: '江西省',
          chinesePinyin: 'jiangxisheng',
          pinyinInitials: 'JXS',
          parentId: '0',
          longitude: 115.816587,
          latitude: 28.637234,
          createTime: '2022-07-05 14:26:10',
          updateTime: '2022-07-05 14:26:10',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1338',
          areaId: '370000',
          areaName: '山东省',
          chinesePinyin: 'shandongsheng',
          pinyinInitials: 'SDS',
          parentId: '0',
          longitude: 117.020725,
          latitude: 36.670201,
          createTime: '2022-07-05 14:26:13',
          updateTime: '2022-07-05 14:26:13',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1491',
          areaId: '410000',
          areaName: '河南省',
          chinesePinyin: 'henansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 113.753094,
          latitude: 34.767052,
          createTime: '2022-07-05 14:26:16',
          updateTime: '2022-07-05 14:26:16',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1666',
          areaId: '420000',
          areaName: '湖北省',
          chinesePinyin: 'hubeisheng',
          pinyinInitials: 'HBS',
          parentId: '0',
          longitude: 114.341552,
          latitude: 30.546222,
          createTime: '2022-07-05 14:26:22',
          updateTime: '2022-07-05 14:26:22',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1783',
          areaId: '430000',
          areaName: '湖南省',
          chinesePinyin: 'hunansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 112.982951,
          latitude: 28.116007,
          createTime: '2022-07-05 14:26:26',
          updateTime: '2022-07-05 14:26:26',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1920',
          areaId: '440000',
          areaName: '广东省',
          chinesePinyin: 'guangdongsheng',
          pinyinInitials: 'GDS',
          parentId: '0',
          longitude: 113.266887,
          latitude: 23.133306,
          createTime: '2022-07-05 14:26:32',
          updateTime: '2022-07-05 14:26:32',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2064',
          areaId: '450000',
          areaName: '广西壮族自治区',
          chinesePinyin: 'guangxizhuangzuzizhiqu',
          pinyinInitials: 'GXZZZZQ',
          parentId: '0',
          longitude: 108.327537,
          latitude: 22.816659,
          createTime: '2022-07-05 14:26:36',
          updateTime: '2022-07-05 14:26:36',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2190',
          areaId: '460000',
          areaName: '海南省',
          chinesePinyin: 'hainansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 110.348781,
          latitude: 20.018639,
          createTime: '2022-07-05 14:26:39',
          updateTime: '2022-07-05 14:26:39',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2220',
          areaId: '500000',
          areaName: '重庆市',
          chinesePinyin: 'zhongqingshi',
          pinyinInitials: 'ZQS',
          parentId: '0',
          longitude: 106.550483,
          latitude: 29.563707,
          createTime: '2022-07-05 14:26:39',
          updateTime: '2022-07-05 14:26:39',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2261',
          areaId: '510000',
          areaName: '四川省',
          chinesePinyin: 'sichuansheng',
          pinyinInitials: 'SCS',
          parentId: '0',
          longitude: 104.076452,
          latitude: 30.651696,
          createTime: '2022-07-05 14:26:40',
          updateTime: '2022-07-05 14:26:40',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2466',
          areaId: '520000',
          areaName: '贵州省',
          chinesePinyin: 'guizhousheng',
          pinyinInitials: 'GZS',
          parentId: '0',
          longitude: 106.705251,
          latitude: 26.600328,
          createTime: '2022-07-05 14:26:47',
          updateTime: '2022-07-05 14:26:47',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2564',
          areaId: '530000',
          areaName: '云南省',
          chinesePinyin: 'yunnansheng',
          pinyinInitials: 'YNS',
          parentId: '0',
          longitude: 102.709372,
          latitude: 25.046432,
          createTime: '2022-07-05 14:26:48',
          updateTime: '2022-07-05 14:26:48',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2710',
          areaId: '540000',
          areaName: '西藏自治区',
          chinesePinyin: 'xizangzizhiqu',
          pinyinInitials: 'XZZZQ',
          parentId: '0',
          longitude: 91.117449,
          latitude: 29.648694,
          createTime: '2022-07-05 14:26:52',
          updateTime: '2022-07-05 14:26:52',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2792',
          areaId: '610000',
          areaName: '陕西省',
          chinesePinyin: 'shanxisheng',
          pinyinInitials: 'SXS',
          parentId: '0',
          longitude: 108.953939,
          latitude: 34.266611,
          createTime: '2022-07-05 14:26:53',
          updateTime: '2022-07-05 14:26:53',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2910',
          areaId: '620000',
          areaName: '甘肃省',
          chinesePinyin: 'gansusheng',
          pinyinInitials: 'GSS',
          parentId: '0',
          longitude: 103.826777,
          latitude: 36.060634,
          createTime: '2022-07-05 14:26:57',
          updateTime: '2022-07-05 14:26:57',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3011',
          areaId: '630000',
          areaName: '青海省',
          chinesePinyin: 'qinghaisheng',
          pinyinInitials: 'QHS',
          parentId: '0',
          longitude: 101.780482,
          latitude: 36.622538,
          createTime: '2022-07-05 14:27:02',
          updateTime: '2022-07-05 14:27:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3065',
          areaId: '640000',
          areaName: '宁夏回族自治区',
          chinesePinyin: 'ningxiahuizuzizhiqu',
          pinyinInitials: 'NXHZZZQ',
          parentId: '0',
          longitude: 106.258889,
          latitude: 38.472273,
          createTime: '2022-07-05 14:27:02',
          updateTime: '2022-07-05 14:27:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3093',
          areaId: '650000',
          areaName: '新疆维吾尔自治区',
          chinesePinyin: 'xinjiangweiwuerzizhiqu',
          pinyinInitials: 'XJWWEZZQ',
          parentId: '0',
          longitude: 87.628579,
          latitude: 43.793301,
          createTime: '2022-07-05 14:27:03',
          updateTime: '2022-07-05 14:27:03',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3215',
          areaId: '710000',
          areaName: '台湾省',
          chinesePinyin: 'taiwansheng',
          pinyinInitials: 'TWS',
          parentId: '0',
          longitude: 121.509062,
          latitude: 25.044332,
          createTime: '2022-07-05 14:27:08',
          updateTime: '2022-07-05 14:27:08'
        },
        {
          id: '3216',
          areaId: '810000',
          areaName: '香港特别行政区',
          chinesePinyin: 'xianggangtebiexingzhengqu',
          pinyinInitials: 'XGTBXZQ',
          parentId: '0',
          longitude: 114.173355,
          latitude: 22.320048,
          createTime: '2022-07-05 14:27:08',
          updateTime: '2022-07-05 14:27:08',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3235',
          areaId: '820000',
          areaName: '澳门特别行政区',
          chinesePinyin: 'aomentebiexingzhengqu',
          pinyinInitials: 'AMTBXZQ',
          parentId: '0',
          longitude: 113.54909,
          latitude: 22.198951,
          createTime: '2022-07-05 14:27:09',
          updateTime: '2022-07-05 14:27:09',
          children: [],
          _loading: false,
          isShow: true
        }
      ],
      msg: 'success'
    }
  }
}

// 新增分类
export function save() {
  return {
    url: areaDispatch.url.addArea,
    type: 'post',
    data: {
      code: 200,
      body: [
        {
          id: '1',
          areaId: '110000',
          areaName: '北京市',
          chinesePinyin: 'beijingshi',
          pinyinInitials: 'BJS',
          parentId: '0',
          longitude: 116.407387,
          latitude: 39.904179,
          createTime: '2022-07-05 14:25:36',
          updateTime: '2022-07-05 14:25:36',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '19',
          areaId: '120000',
          areaName: '天津市',
          chinesePinyin: 'tianjinshi',
          pinyinInitials: 'TJS',
          parentId: '0',
          longitude: 117.201509,
          latitude: 39.085318,
          createTime: '2022-07-05 14:25:37',
          updateTime: '2022-07-05 14:25:37',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '37',
          areaId: '130000',
          areaName: '河北省',
          chinesePinyin: 'hebeisheng',
          pinyinInitials: 'HBS',
          parentId: '0',
          longitude: 114.530399,
          latitude: 38.037707,
          createTime: '2022-07-05 14:25:37',
          updateTime: '2022-07-05 14:25:37',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '216',
          areaId: '140000',
          areaName: '山西省',
          chinesePinyin: 'shanxisheng',
          pinyinInitials: 'SXS',
          parentId: '0',
          longitude: 112.578781,
          latitude: 37.813948,
          createTime: '2022-07-05 14:25:41',
          updateTime: '2022-07-05 14:25:41',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '345',
          areaId: '150000',
          areaName: '内蒙古自治区',
          chinesePinyin: 'neimengguzizhiqu',
          pinyinInitials: 'NMGZZQ',
          parentId: '0',
          longitude: 111.765226,
          latitude: 40.818233,
          createTime: '2022-07-05 14:25:46',
          updateTime: '2022-07-05 14:25:46',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '461',
          areaId: '210000',
          areaName: '辽宁省',
          chinesePinyin: 'liaoningsheng',
          pinyinInitials: 'LNS',
          parentId: '0',
          longitude: 123.435093,
          latitude: 41.836743,
          createTime: '2022-07-05 14:25:48',
          updateTime: '2022-07-05 14:25:48',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '576',
          areaId: '220000',
          areaName: '吉林省',
          chinesePinyin: 'jilinsheng',
          pinyinInitials: 'JLS',
          parentId: '0',
          longitude: 125.325802,
          latitude: 43.896942,
          createTime: '2022-07-05 14:25:52',
          updateTime: '2022-07-05 14:25:52',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '646',
          areaId: '230000',
          areaName: '黑龙江省',
          chinesePinyin: 'heilongjiangsheng',
          pinyinInitials: 'HLJS',
          parentId: '0',
          longitude: 126.661998,
          latitude: 45.742253,
          createTime: '2022-07-05 14:25:54',
          updateTime: '2022-07-05 14:25:54',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '782',
          areaId: '310000',
          areaName: '上海市',
          chinesePinyin: 'shanghaishi',
          pinyinInitials: 'SHS',
          parentId: '0',
          longitude: 121.473667,
          latitude: 31.230525,
          createTime: '2022-07-05 14:25:57',
          updateTime: '2022-07-05 14:25:57',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '800',
          areaId: '320000',
          areaName: '江苏省',
          chinesePinyin: 'jiangsusheng',
          pinyinInitials: 'JSS',
          parentId: '0',
          longitude: 118.763563,
          latitude: 32.061377,
          createTime: '2022-07-05 14:25:58',
          updateTime: '2022-07-05 14:25:58',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '909',
          areaId: '330000',
          areaName: '浙江省',
          chinesePinyin: 'zhejiangsheng',
          pinyinInitials: 'ZJS',
          parentId: '0',
          longitude: 120.152575,
          latitude: 30.266619,
          createTime: '2022-07-05 14:26:02',
          updateTime: '2022-07-05 14:26:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1011',
          areaId: '340000',
          areaName: '安徽省',
          chinesePinyin: 'anhuisheng',
          pinyinInitials: 'AHS',
          parentId: '0',
          longitude: 117.330139,
          latitude: 31.734559,
          createTime: '2022-07-05 14:26:03',
          updateTime: '2022-07-05 14:26:03',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1132',
          areaId: '350000',
          areaName: '福建省',
          chinesePinyin: 'fujiansheng',
          pinyinInitials: 'FJS',
          parentId: '0',
          longitude: 119.296194,
          latitude: 26.101082,
          createTime: '2022-07-05 14:26:07',
          updateTime: '2022-07-05 14:26:07',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1226',
          areaId: '360000',
          areaName: '江西省',
          chinesePinyin: 'jiangxisheng',
          pinyinInitials: 'JXS',
          parentId: '0',
          longitude: 115.816587,
          latitude: 28.637234,
          createTime: '2022-07-05 14:26:10',
          updateTime: '2022-07-05 14:26:10',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1338',
          areaId: '370000',
          areaName: '山东省',
          chinesePinyin: 'shandongsheng',
          pinyinInitials: 'SDS',
          parentId: '0',
          longitude: 117.020725,
          latitude: 36.670201,
          createTime: '2022-07-05 14:26:13',
          updateTime: '2022-07-05 14:26:13',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1491',
          areaId: '410000',
          areaName: '河南省',
          chinesePinyin: 'henansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 113.753094,
          latitude: 34.767052,
          createTime: '2022-07-05 14:26:16',
          updateTime: '2022-07-05 14:26:16',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1666',
          areaId: '420000',
          areaName: '湖北省',
          chinesePinyin: 'hubeisheng',
          pinyinInitials: 'HBS',
          parentId: '0',
          longitude: 114.341552,
          latitude: 30.546222,
          createTime: '2022-07-05 14:26:22',
          updateTime: '2022-07-05 14:26:22',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1783',
          areaId: '430000',
          areaName: '湖南省',
          chinesePinyin: 'hunansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 112.982951,
          latitude: 28.116007,
          createTime: '2022-07-05 14:26:26',
          updateTime: '2022-07-05 14:26:26',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1920',
          areaId: '440000',
          areaName: '广东省',
          chinesePinyin: 'guangdongsheng',
          pinyinInitials: 'GDS',
          parentId: '0',
          longitude: 113.266887,
          latitude: 23.133306,
          createTime: '2022-07-05 14:26:32',
          updateTime: '2022-07-05 14:26:32',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2064',
          areaId: '450000',
          areaName: '广西壮族自治区',
          chinesePinyin: 'guangxizhuangzuzizhiqu',
          pinyinInitials: 'GXZZZZQ',
          parentId: '0',
          longitude: 108.327537,
          latitude: 22.816659,
          createTime: '2022-07-05 14:26:36',
          updateTime: '2022-07-05 14:26:36',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2190',
          areaId: '460000',
          areaName: '海南省',
          chinesePinyin: 'hainansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 110.348781,
          latitude: 20.018639,
          createTime: '2022-07-05 14:26:39',
          updateTime: '2022-07-05 14:26:39',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2220',
          areaId: '500000',
          areaName: '重庆市',
          chinesePinyin: 'zhongqingshi',
          pinyinInitials: 'ZQS',
          parentId: '0',
          longitude: 106.550483,
          latitude: 29.563707,
          createTime: '2022-07-05 14:26:39',
          updateTime: '2022-07-05 14:26:39',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2261',
          areaId: '510000',
          areaName: '四川省',
          chinesePinyin: 'sichuansheng',
          pinyinInitials: 'SCS',
          parentId: '0',
          longitude: 104.076452,
          latitude: 30.651696,
          createTime: '2022-07-05 14:26:40',
          updateTime: '2022-07-05 14:26:40',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2466',
          areaId: '520000',
          areaName: '贵州省',
          chinesePinyin: 'guizhousheng',
          pinyinInitials: 'GZS',
          parentId: '0',
          longitude: 106.705251,
          latitude: 26.600328,
          createTime: '2022-07-05 14:26:47',
          updateTime: '2022-07-05 14:26:47',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2564',
          areaId: '530000',
          areaName: '云南省',
          chinesePinyin: 'yunnansheng',
          pinyinInitials: 'YNS',
          parentId: '0',
          longitude: 102.709372,
          latitude: 25.046432,
          createTime: '2022-07-05 14:26:48',
          updateTime: '2022-07-05 14:26:48',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2710',
          areaId: '540000',
          areaName: '西藏自治区',
          chinesePinyin: 'xizangzizhiqu',
          pinyinInitials: 'XZZZQ',
          parentId: '0',
          longitude: 91.117449,
          latitude: 29.648694,
          createTime: '2022-07-05 14:26:52',
          updateTime: '2022-07-05 14:26:52',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2792',
          areaId: '610000',
          areaName: '陕西省',
          chinesePinyin: 'shanxisheng',
          pinyinInitials: 'SXS',
          parentId: '0',
          longitude: 108.953939,
          latitude: 34.266611,
          createTime: '2022-07-05 14:26:53',
          updateTime: '2022-07-05 14:26:53',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2910',
          areaId: '620000',
          areaName: '甘肃省',
          chinesePinyin: 'gansusheng',
          pinyinInitials: 'GSS',
          parentId: '0',
          longitude: 103.826777,
          latitude: 36.060634,
          createTime: '2022-07-05 14:26:57',
          updateTime: '2022-07-05 14:26:57',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3011',
          areaId: '630000',
          areaName: '青海省',
          chinesePinyin: 'qinghaisheng',
          pinyinInitials: 'QHS',
          parentId: '0',
          longitude: 101.780482,
          latitude: 36.622538,
          createTime: '2022-07-05 14:27:02',
          updateTime: '2022-07-05 14:27:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3065',
          areaId: '640000',
          areaName: '宁夏回族自治区',
          chinesePinyin: 'ningxiahuizuzizhiqu',
          pinyinInitials: 'NXHZZZQ',
          parentId: '0',
          longitude: 106.258889,
          latitude: 38.472273,
          createTime: '2022-07-05 14:27:02',
          updateTime: '2022-07-05 14:27:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3093',
          areaId: '650000',
          areaName: '新疆维吾尔自治区',
          chinesePinyin: 'xinjiangweiwuerzizhiqu',
          pinyinInitials: 'XJWWEZZQ',
          parentId: '0',
          longitude: 87.628579,
          latitude: 43.793301,
          createTime: '2022-07-05 14:27:03',
          updateTime: '2022-07-05 14:27:03',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3215',
          areaId: '710000',
          areaName: '台湾省',
          chinesePinyin: 'taiwansheng',
          pinyinInitials: 'TWS',
          parentId: '0',
          longitude: 121.509062,
          latitude: 25.044332,
          createTime: '2022-07-05 14:27:08',
          updateTime: '2022-07-05 14:27:08'
        },
        {
          id: '3216',
          areaId: '810000',
          areaName: '香港特别行政区',
          chinesePinyin: 'xianggangtebiexingzhengqu',
          pinyinInitials: 'XGTBXZQ',
          parentId: '0',
          longitude: 114.173355,
          latitude: 22.320048,
          createTime: '2022-07-05 14:27:08',
          updateTime: '2022-07-05 14:27:08',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3235',
          areaId: '820000',
          areaName: '澳门特别行政区',
          chinesePinyin: 'aomentebiexingzhengqu',
          pinyinInitials: 'AMTBXZQ',
          parentId: '0',
          longitude: 113.54909,
          latitude: 22.198951,
          createTime: '2022-07-05 14:27:09',
          updateTime: '2022-07-05 14:27:09',
          children: [],
          _loading: false,
          isShow: true
        }
      ],
      msg: 'success'
    }
  }
}

// 更新分类
export function update() {
  return {
    url: areaDispatch.url.updateAreaById,
    type: 'put',
    data: {
      code: 200,
      body: [
        {
          id: '1',
          areaId: '110000',
          areaName: '北京市',
          chinesePinyin: 'beijingshi',
          pinyinInitials: 'BJS',
          parentId: '0',
          longitude: 116.407387,
          latitude: 39.904179,
          createTime: '2022-07-05 14:25:36',
          updateTime: '2022-07-05 14:25:36',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '19',
          areaId: '120000',
          areaName: '天津市',
          chinesePinyin: 'tianjinshi',
          pinyinInitials: 'TJS',
          parentId: '0',
          longitude: 117.201509,
          latitude: 39.085318,
          createTime: '2022-07-05 14:25:37',
          updateTime: '2022-07-05 14:25:37',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '37',
          areaId: '130000',
          areaName: '河北省',
          chinesePinyin: 'hebeisheng',
          pinyinInitials: 'HBS',
          parentId: '0',
          longitude: 114.530399,
          latitude: 38.037707,
          createTime: '2022-07-05 14:25:37',
          updateTime: '2022-07-05 14:25:37',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '216',
          areaId: '140000',
          areaName: '山西省',
          chinesePinyin: 'shanxisheng',
          pinyinInitials: 'SXS',
          parentId: '0',
          longitude: 112.578781,
          latitude: 37.813948,
          createTime: '2022-07-05 14:25:41',
          updateTime: '2022-07-05 14:25:41',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '345',
          areaId: '150000',
          areaName: '内蒙古自治区',
          chinesePinyin: 'neimengguzizhiqu',
          pinyinInitials: 'NMGZZQ',
          parentId: '0',
          longitude: 111.765226,
          latitude: 40.818233,
          createTime: '2022-07-05 14:25:46',
          updateTime: '2022-07-05 14:25:46',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '461',
          areaId: '210000',
          areaName: '辽宁省',
          chinesePinyin: 'liaoningsheng',
          pinyinInitials: 'LNS',
          parentId: '0',
          longitude: 123.435093,
          latitude: 41.836743,
          createTime: '2022-07-05 14:25:48',
          updateTime: '2022-07-05 14:25:48',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '576',
          areaId: '220000',
          areaName: '吉林省',
          chinesePinyin: 'jilinsheng',
          pinyinInitials: 'JLS',
          parentId: '0',
          longitude: 125.325802,
          latitude: 43.896942,
          createTime: '2022-07-05 14:25:52',
          updateTime: '2022-07-05 14:25:52',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '646',
          areaId: '230000',
          areaName: '黑龙江省',
          chinesePinyin: 'heilongjiangsheng',
          pinyinInitials: 'HLJS',
          parentId: '0',
          longitude: 126.661998,
          latitude: 45.742253,
          createTime: '2022-07-05 14:25:54',
          updateTime: '2022-07-05 14:25:54',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '782',
          areaId: '310000',
          areaName: '上海市',
          chinesePinyin: 'shanghaishi',
          pinyinInitials: 'SHS',
          parentId: '0',
          longitude: 121.473667,
          latitude: 31.230525,
          createTime: '2022-07-05 14:25:57',
          updateTime: '2022-07-05 14:25:57',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '800',
          areaId: '320000',
          areaName: '江苏省',
          chinesePinyin: 'jiangsusheng',
          pinyinInitials: 'JSS',
          parentId: '0',
          longitude: 118.763563,
          latitude: 32.061377,
          createTime: '2022-07-05 14:25:58',
          updateTime: '2022-07-05 14:25:58',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '909',
          areaId: '330000',
          areaName: '浙江省',
          chinesePinyin: 'zhejiangsheng',
          pinyinInitials: 'ZJS',
          parentId: '0',
          longitude: 120.152575,
          latitude: 30.266619,
          createTime: '2022-07-05 14:26:02',
          updateTime: '2022-07-05 14:26:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1011',
          areaId: '340000',
          areaName: '安徽省',
          chinesePinyin: 'anhuisheng',
          pinyinInitials: 'AHS',
          parentId: '0',
          longitude: 117.330139,
          latitude: 31.734559,
          createTime: '2022-07-05 14:26:03',
          updateTime: '2022-07-05 14:26:03',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1132',
          areaId: '350000',
          areaName: '福建省',
          chinesePinyin: 'fujiansheng',
          pinyinInitials: 'FJS',
          parentId: '0',
          longitude: 119.296194,
          latitude: 26.101082,
          createTime: '2022-07-05 14:26:07',
          updateTime: '2022-07-05 14:26:07',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1226',
          areaId: '360000',
          areaName: '江西省',
          chinesePinyin: 'jiangxisheng',
          pinyinInitials: 'JXS',
          parentId: '0',
          longitude: 115.816587,
          latitude: 28.637234,
          createTime: '2022-07-05 14:26:10',
          updateTime: '2022-07-05 14:26:10',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1338',
          areaId: '370000',
          areaName: '山东省',
          chinesePinyin: 'shandongsheng',
          pinyinInitials: 'SDS',
          parentId: '0',
          longitude: 117.020725,
          latitude: 36.670201,
          createTime: '2022-07-05 14:26:13',
          updateTime: '2022-07-05 14:26:13',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1491',
          areaId: '410000',
          areaName: '河南省',
          chinesePinyin: 'henansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 113.753094,
          latitude: 34.767052,
          createTime: '2022-07-05 14:26:16',
          updateTime: '2022-07-05 14:26:16',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1666',
          areaId: '420000',
          areaName: '湖北省',
          chinesePinyin: 'hubeisheng',
          pinyinInitials: 'HBS',
          parentId: '0',
          longitude: 114.341552,
          latitude: 30.546222,
          createTime: '2022-07-05 14:26:22',
          updateTime: '2022-07-05 14:26:22',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1783',
          areaId: '430000',
          areaName: '湖南省',
          chinesePinyin: 'hunansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 112.982951,
          latitude: 28.116007,
          createTime: '2022-07-05 14:26:26',
          updateTime: '2022-07-05 14:26:26',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '1920',
          areaId: '440000',
          areaName: '广东省',
          chinesePinyin: 'guangdongsheng',
          pinyinInitials: 'GDS',
          parentId: '0',
          longitude: 113.266887,
          latitude: 23.133306,
          createTime: '2022-07-05 14:26:32',
          updateTime: '2022-07-05 14:26:32',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2064',
          areaId: '450000',
          areaName: '广西壮族自治区',
          chinesePinyin: 'guangxizhuangzuzizhiqu',
          pinyinInitials: 'GXZZZZQ',
          parentId: '0',
          longitude: 108.327537,
          latitude: 22.816659,
          createTime: '2022-07-05 14:26:36',
          updateTime: '2022-07-05 14:26:36',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2190',
          areaId: '460000',
          areaName: '海南省',
          chinesePinyin: 'hainansheng',
          pinyinInitials: 'HNS',
          parentId: '0',
          longitude: 110.348781,
          latitude: 20.018639,
          createTime: '2022-07-05 14:26:39',
          updateTime: '2022-07-05 14:26:39',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2220',
          areaId: '500000',
          areaName: '重庆市',
          chinesePinyin: 'zhongqingshi',
          pinyinInitials: 'ZQS',
          parentId: '0',
          longitude: 106.550483,
          latitude: 29.563707,
          createTime: '2022-07-05 14:26:39',
          updateTime: '2022-07-05 14:26:39',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2261',
          areaId: '510000',
          areaName: '四川省',
          chinesePinyin: 'sichuansheng',
          pinyinInitials: 'SCS',
          parentId: '0',
          longitude: 104.076452,
          latitude: 30.651696,
          createTime: '2022-07-05 14:26:40',
          updateTime: '2022-07-05 14:26:40',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2466',
          areaId: '520000',
          areaName: '贵州省',
          chinesePinyin: 'guizhousheng',
          pinyinInitials: 'GZS',
          parentId: '0',
          longitude: 106.705251,
          latitude: 26.600328,
          createTime: '2022-07-05 14:26:47',
          updateTime: '2022-07-05 14:26:47',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2564',
          areaId: '530000',
          areaName: '云南省',
          chinesePinyin: 'yunnansheng',
          pinyinInitials: 'YNS',
          parentId: '0',
          longitude: 102.709372,
          latitude: 25.046432,
          createTime: '2022-07-05 14:26:48',
          updateTime: '2022-07-05 14:26:48',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2710',
          areaId: '540000',
          areaName: '西藏自治区',
          chinesePinyin: 'xizangzizhiqu',
          pinyinInitials: 'XZZZQ',
          parentId: '0',
          longitude: 91.117449,
          latitude: 29.648694,
          createTime: '2022-07-05 14:26:52',
          updateTime: '2022-07-05 14:26:52',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2792',
          areaId: '610000',
          areaName: '陕西省',
          chinesePinyin: 'shanxisheng',
          pinyinInitials: 'SXS',
          parentId: '0',
          longitude: 108.953939,
          latitude: 34.266611,
          createTime: '2022-07-05 14:26:53',
          updateTime: '2022-07-05 14:26:53',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '2910',
          areaId: '620000',
          areaName: '甘肃省',
          chinesePinyin: 'gansusheng',
          pinyinInitials: 'GSS',
          parentId: '0',
          longitude: 103.826777,
          latitude: 36.060634,
          createTime: '2022-07-05 14:26:57',
          updateTime: '2022-07-05 14:26:57',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3011',
          areaId: '630000',
          areaName: '青海省',
          chinesePinyin: 'qinghaisheng',
          pinyinInitials: 'QHS',
          parentId: '0',
          longitude: 101.780482,
          latitude: 36.622538,
          createTime: '2022-07-05 14:27:02',
          updateTime: '2022-07-05 14:27:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3065',
          areaId: '640000',
          areaName: '宁夏回族自治区',
          chinesePinyin: 'ningxiahuizuzizhiqu',
          pinyinInitials: 'NXHZZZQ',
          parentId: '0',
          longitude: 106.258889,
          latitude: 38.472273,
          createTime: '2022-07-05 14:27:02',
          updateTime: '2022-07-05 14:27:02',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3093',
          areaId: '650000',
          areaName: '新疆维吾尔自治区',
          chinesePinyin: 'xinjiangweiwuerzizhiqu',
          pinyinInitials: 'XJWWEZZQ',
          parentId: '0',
          longitude: 87.628579,
          latitude: 43.793301,
          createTime: '2022-07-05 14:27:03',
          updateTime: '2022-07-05 14:27:03',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3215',
          areaId: '710000',
          areaName: '台湾省',
          chinesePinyin: 'taiwansheng',
          pinyinInitials: 'TWS',
          parentId: '0',
          longitude: 121.509062,
          latitude: 25.044332,
          createTime: '2022-07-05 14:27:08',
          updateTime: '2022-07-05 14:27:08'
        },
        {
          id: '3216',
          areaId: '810000',
          areaName: '香港特别行政区',
          chinesePinyin: 'xianggangtebiexingzhengqu',
          pinyinInitials: 'XGTBXZQ',
          parentId: '0',
          longitude: 114.173355,
          latitude: 22.320048,
          createTime: '2022-07-05 14:27:08',
          updateTime: '2022-07-05 14:27:08',
          children: [],
          _loading: false,
          isShow: true
        },
        {
          id: '3235',
          areaId: '820000',
          areaName: '澳门特别行政区',
          chinesePinyin: 'aomentebiexingzhengqu',
          pinyinInitials: 'AMTBXZQ',
          parentId: '0',
          longitude: 113.54909,
          latitude: 22.198951,
          createTime: '2022-07-05 14:27:09',
          updateTime: '2022-07-05 14:27:09',
          children: [],
          _loading: false,
          isShow: true
        }
      ],
      msg: 'success'
    }
  }
}
// 删除分类
export function del() {
  return {
    url: areaDispatch.url.deleteAreaById,
    type: 'delete',
    data: {
      code: 200,
      body: [],
      msg: 'success'
    }
  }
}
