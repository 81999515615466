import { DEFAULT_COLOR, MAIN_COLOR } from '@/constant'
import { localRead, localSave } from '@/libs/util'
import variables from '@/styles/variables.module.scss'
export default {
  namespaced: true,
  state: {
    mainColor: localRead(MAIN_COLOR) || DEFAULT_COLOR,
    variables
  },
  getters: {
    getMainColor: (state) => state.mainColor
  },
  mutations: {
    /**
     * 设置主题色
     */
    setMainColor(state, newColor) {
      state.mainColor = newColor
      state.variables.menuBg = newColor
      localSave(MAIN_COLOR, newColor)
    }
  },
  actions: {}
}
