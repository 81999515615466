export const errCode = {
  GENERAL_ERROR: { key: 10000, value: '通用异常错误' },
  PARAMETER_ERROR: { key: 10001, value: '入参检验错误' },
  LOGIN_ERROR: { key: 10003, value: '没有找到合适的登陆处理方法' },
  TOKEN_ERROR: { key: 10004, value: '令牌不合法或者过期' },
  UNAUTHENTICATED_ERROR: { key: 10005, value: '用户未被授权' },
  REFRESH_TOKEN_ERROR: { key: 10006, value: '刷新令牌已过期' },
  ACCOUNT_ERROR: { key: 10007, value: '账号密码不匹配' },
  CAPTCHA_ERROR: { key: 10008, value: '验证码已过期' },
  GROUP_HAVE_USER_ERROR: { key: 10009, value: '机构下有用户存在' },
  GROUP_HAVE_ROLE_ERROR: { key: 10010, value: '机构下有角色存在' },
  GROUP_HAVE_USER_AND_ROLE_ERROR: {
    key: 10011,
    value: '机构下有用户和角色存在'
  },
  WECHAT_USER_ERROR: { key: 20004, value: '获取用户wx' }
}
