<template>
  <!-- 主题图标
  v-bind：https://v3.cn.vuejs.org/api/instance-properties.html#attrs -->
  <Dropdown v-bind="$attrs" class="theme" @on-click="handleSetTheme">
    <div>
      <Icon id="guide-theme" size="22" custom="iconfont i-clothesfill"></Icon>
    </div>
    <template #list>
      <DropdownMenu>
        <DropdownItem name="color">
          {{ $t('themeColorChange') }}
        </DropdownItem>
      </DropdownMenu>
    </template>
  </Dropdown>
  <!-- 展示弹出层 -->
  <div>
    <select-color v-model="selectColorVisible"></select-color>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import SelectColor from './components/SelectColor.vue'
const selectColorVisible = ref(false)
const handleSetTheme = () => {
  selectColorVisible.value = true
}
</script>

<style lang="scss" scoped></style>
