<template>
  <div :class="{ show: isShow }" class="header-search">
    <Icon
      id="guide-search"
      size="20"
      custom="iconfont i-search"
      @click.stop="onShowClick"
    ></Icon>
    <Select
      ref="headerSearchSelectRef"
      class="header-search-select"
      v-model="search"
      filterable
      clearable
      placeholder="Search"
      :remote-method="querySearch"
      @on-change="onSelectChange"
    >
      <Option
        v-for="option in searchOptions"
        :key="option.refIndex"
        :label="option.item.title.join(' > ')"
        :value="JSON.stringify(option.item)"
      ></Option>
    </Select>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { generateRoutes } from './FuseData'
import {
  watchSwitchLang,
  filterRoutes,
  getRoutesName,
  getMenuByRouter
} from '@/libs/util'
import routes from '@/router/routes'
import communityRoutes from '@/router/modules/community'
import Fuse from 'fuse.js'
import { useRouter } from 'vue-router'
import store from '@/store'

// 检索数据源
let searchPool = computed(() => {
  const routesData = store.getters['user/getRoutes']
  const routesName = getRoutesName(routesData)
  const newRoutes = filterRoutes(communityRoutes, routesName)
  const result = getMenuByRouter(newRoutes.concat(routes))
  return generateRoutes(result)
})

/**
 * 搜索库相关
 */
let fuse
const initFuse = (searchPool) => {
  fuse = new Fuse(searchPool, {
    // 是否按优先级进行排序
    shouldSort: true,
    // 匹配长度超过这个值的才会被认为是匹配的
    minMatchCharLength: 1,
    // 将被搜索的键列表。 这支持嵌套路径、加权搜索、在字符串和对象数组中搜索。
    // name：搜索的键
    // weight：对应的权重
    keys: [
      {
        name: 'title',
        weight: 0.7
      },
      {
        name: 'path',
        weight: 0.3
      }
    ]
  })
}
initFuse(searchPool.value)
const headerSearchSelectRef = ref(null)
// 控制 search 显示
const isShow = ref(false)
// Select 实例
const onShowClick = () => {
  isShow.value = !isShow.value
  headerSearchSelectRef.value.focus()
}

// search 相关
const search = ref('')
// 搜索方法
const searchOptions = ref([])
const querySearch = (query) => {
  if (query) {
    searchOptions.value = fuse.search(query)
  }
}
// 选中回调
const router = useRouter()
const onSelectChange = (val) => {
  if (val) {
    const route = JSON.parse(val)
    if (route.href) {
      window.open(route.href)
    } else {
      router.push(route.path)
    }
  }
}
/**
 * 关闭 search 的处理事件
 */
const onClose = () => {
  isShow.value = false
  headerSearchSelectRef.value.clearSingleSelect()
  searchOptions.value = []
}
/**
 * 监听 search 打开，处理 close 事件
 */
watch(isShow, (val) => {
  if (headerSearchSelectRef.value.isFocused) {
    if (val) {
      document.body.addEventListener('click', onClose, { passive: false })
    } else {
      document.body.removeEventListener('click', onClose)
    }
  }
})
// 处理国际化
watchSwitchLang(() => {
  searchPool = computed(() => {
    const routesData = store.getters['user/getRoutes']
    const routesName = getRoutesName(routesData)
    const newRoutes = filterRoutes(communityRoutes, routesName)
    const result = getMenuByRouter(newRoutes.concat(routes))
    return generateRoutes(result)
  })
  initFuse(searchPool.value)
})
</script>

<style lang="scss" scoped>
.header-search {
  .search-icon {
    cursor: pointer;
    font-size: 18px;
    vertical-align: middle;
  }
  .header-search-select {
    font-size: 18px;
    transition: width 0.2s;
    width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;

    :deep(.ivu-select-selection) {
      padding-left: 6px;
    }

    :deep(.ivu-select-input) {
      border-radius: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      box-shadow: none !important;
      border-bottom: 1px solid #d9d9d9;
      // vertical-align: middle;
    }
  }
  &.show {
    .header-search-select {
      width: 210px;
      margin-left: 10px;
    }
  }
}
</style>
