import '@/libs/ployfill'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import './styles/index.scss'
// 鉴权
import '@/router/permission'
// 开启mock
import '../mock/index.js'
// 国际化
import i18n from '@/i18n'
// 注册自定义指令
import { directive as clickOutside } from 'v-click-outside-x'
import importDirective from '@/directive'
// 高德地图
import VueAMap from '@vuemap/vue-amap'
import '@vuemap/vue-amap/dist/style.css'
const app = createApp(App)
importDirective(app)
app.directive('clickOutside', clickOutside)

app.use(store).use(router).use(i18n).use(ViewUIPlus).use(VueAMap).mount('#app')
