<template>
  <div class="tags-nav">
    <div class="close-con">
      <Dropdown transfer @on-click="handleTagsOption" style="margin-top: 3px">
        <Button size="small" type="text">
          <Icon :size="18" type="ios-close-circle-outline" />
        </Button>
        <template #list>
          <DropdownMenu>
            <DropdownItem name="close-all">{{ menuList.all }}</DropdownItem>
            <DropdownItem name="close-others">{{
              menuList.others
            }}</DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
    <ul
      v-show="visible"
      :style="{ left: contextMenuLeft + 'px', top: contextMenuTop + 'px' }"
      class="contextmenu"
    >
      <li
        v-for="(item, key) of menuList"
        @click="handleTagsOption(key)"
        :key="key"
      >
        {{ item }}
      </li>
    </ul>
    <div class="btn-con left-btn">
      <Button type="text" @click="handleScroll(240)">
        <Icon :size="18" type="ios-arrow-back" />
      </Button>
    </div>
    <div class="btn-con right-btn">
      <Button type="text" @click="handleScroll(-240)">
        <Icon :size="18" type="ios-arrow-forward" />
      </Button>
    </div>
    <div
      class="scroll-outer"
      ref="scrollOuter"
      @DOMMouseScroll="handlescroll"
      @mousewheel="handlescroll"
    >
      <div
        ref="scrollBody"
        class="scroll-body"
        :style="{ left: tagBodyLeft + 'px' }"
      >
        <transition-group name="taglist-moving-animation">
          <Tag
            type="dot"
            v-for="(item, index) in list"
            ref="tagsPageOpened"
            :key="`tag-nav-${index}`"
            :name="item.name"
            :data-route-item="item"
            @on-close="handleClose(item)"
            @click="handleClick(item)"
            :closable="item.name !== homename"
            :color="isCurrentTag(item) ? 'primary' : 'default'"
            @contextmenu.prevent="contextMenu(item, $event)"
            >{{ showTitleInside(item) }}</Tag
          >
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  routeEqual,
  showTitle,
  getNewTagList,
  watchSwitchLang
} from '@/libs/util'
import beforeClose from '@/router/before-close'
import config from '@/config'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { reactive, toRefs, ref } from '@vue/reactivity'
import {
  computed,
  nextTick,
  getCurrentInstance,
  watch
} from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
const i18n = useI18n()
const data = reactive({
  list: [],
  tagBodyLeft: 0,
  rightOffset: 40,
  outerPadding: 4,
  contextMenuLeft: 0,
  contextMenuTop: 0,
  visible: false,
  refsTag: null,
  homename: config.homeName,
  menuList: {
    others: i18n.t('closeOther'),
    all: i18n.t('closeAll')
  }
})
const store = useStore()
data.list = computed(() => store.state.app.tagNavList)
// 当前路由
const router = useRouter()
const route = useRoute()
const currentRouteObj = computed(() => {
  const { name, params, query } = route
  return { name, params, query }
})
const isCurrentTag = (item) => {
  return routeEqual(currentRouteObj.value, item)
}
const scrollOuter = ref(null)
const scrollBody = ref(null)
const moveToView = (tag) => {
  const outerWidth = scrollOuter.value.offsetWidth
  const bodyWidth = scrollBody.value.offsetWidth
  if (bodyWidth < outerWidth) {
    data.tagBodyLeft = 0
  } else if (tag.offsetLeft < -data.tagBodyLeft) {
    // 标签在可视区域左侧
    data.tagBodyLeft = -tag.offsetLeft + data.outerPadding
  } else if (
    tag.offsetLeft > -data.tagBodyLeft &&
    tag.offsetLeft + tag.offsetWidth < -data.tagBodyLeft + outerWidth
  ) {
    // 标签在可视区域
    data.tagBodyLeft = Math.min(
      0,
      outerWidth - tag.offsetWidth - tag.offsetLeft - data.outerPadding
    )
  } else {
    // 标签在可视区域右侧
    data.tagBodyLeft = -(
      tag.offsetLeft -
      (outerWidth - data.outerPadding - tag.offsetWidth)
    )
  }
}
const tagsPageOpened = ref(null)
const getTagElementByRoute = (route) => {
  nextTick(() => {
    tagsPageOpened.value.forEach((item, index) => {
      if (routeEqual(route, item.$attrs['data-route-item'])) {
        const tag = tagsPageOpened.value[index].$el
        moveToView(tag)
      }
    })
  })
}
getTagElementByRoute(route)
const handleCloseTag = (res, type, to) => {
  if (type !== 'others') {
    if (type === 'all') {
      turnToPage(config.homeName)
    } else {
      if (routeEqual(route, to)) {
        store.commit('app/closeTag', to)
      }
    }
  }
  store.commit('app/setTagNavList', res)
}
const handleTagsOption = (type) => {
  if (type.includes('all')) {
    // 关闭所有，除了home
    const res = data.list.filter((item) => item.name === config.homeName)
    handleCloseTag(res, 'all')
  } else if (type.includes('others')) {
    // 关闭除当前页和home页的其他页
    const res = data.list.filter(
      (item) =>
        routeEqual(currentRouteObj.value, item) || item.name === config.homeName
    )
    handleCloseTag(res, 'others', currentRouteObj.value)
    setTimeout(() => {
      getTagElementByRoute(currentRouteObj.value)
    }, 100)
  }
}
const handlescroll = (e) => {
  const type = e.type
  let delta = 0
  if (type === 'DOMMouseScroll' || type === 'mousewheel') {
    delta = e.wheelDelta ? e.wheelDelta : -(e.detail || 0) * 40
  }
  handleScroll(delta)
}
const handleScroll = (offset) => {
  const outerWidth = scrollOuter.value.offsetWidth
  const bodyWidth = scrollBody.value.offsetWidth
  if (offset > 0) {
    data.tagBodyLeft = Math.min(0, data.tagBodyLeft + offset)
  } else {
    if (outerWidth < bodyWidth) {
      if (data.tagBodyLeft < -(bodyWidth - outerWidth)) {
        data.tagBodyLeft = data.tagBodyLeft
      } else {
        data.tagBodyLeft = Math.max(
          data.tagBodyLeft + offset,
          outerWidth - bodyWidth
        )
      }
    } else {
      data.tagBodyLeft = 0
    }
  }
}
const close = (route) => {
  const res = data.list.filter((item) => !routeEqual(route, item))
  handleCloseTag(res, undefined, route)
}
const handleClose = (current) => {
  if (
    current.meta &&
    current.meta.beforeCloseName &&
    current.meta.beforeCloseName in beforeClose
  ) {
    new Promise(beforeClose[current.meta.beforeCloseName]).then((close) => {
      if (close) {
        close(current)
      }
    })
  } else {
    close(current)
  }
}
const turnToPage = (route) => {
  let { name, params, query } = {}
  if (typeof route === 'string') name = route
  else {
    name = route.name
    params = route.params
    query = route.query
  }
  if (name.indexOf('isTurnByHref_') > -1) {
    window.open(name.split('_')[1])
    return
  }
  router.push({
    name,
    params,
    query
  })
}
const handleClick = (item) => {
  turnToPage(item)
}
const showTitleInside = (item) => {
  return showTitle(item, this)
}
const { proxy } = getCurrentInstance()
const contextMenu = (item, e) => {
  if (item.name === config.homeName) {
    return
  }
  data.visible = true
  const offsetLeft = proxy.$el.getBoundingClientRect().left
  data.contextMenuLeft = e.clientX - offsetLeft + 10
  data.contextMenuTop = e.clientY - 64
}
const closeMenu = () => {
  data.visible = false
}
watch(
  () => route,
  (to) => {
    const { name, query, params, meta } = to
    store.commit('app/addTag', {
      route: { name, query, params, meta },
      type: 'push'
    })
    store.commit('app/setTagNavList', getNewTagList(data.list, to))
    getTagElementByRoute(to)
  },
  { immediate: true, deep: true }
)
watch(
  () => data.visible,
  (value) => {
    if (value) {
      document.body.addEventListener('click', closeMenu, { passive: false })
    } else {
      document.body.removeEventListener('click', closeMenu)
    }
  }
)
// 处理国际化
watchSwitchLang(() => {
  data.menuList = computed(() => {
    return {
      others: i18n.t('closeOther'),
      all: i18n.t('closeAll')
    }
  })
})
const {
  tagBodyLeft,
  contextMenuLeft,
  contextMenuTop,
  visible,
  menuList,
  homename,
  list
} = toRefs(data)
</script>

<style lang="scss" scoped>
.tags-nav {
  position: relative;
  height: 37px;
  width: 100%;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);

  .close-con {
    z-index: 10;
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    background: #fff;
    text-align: center;
    border-top: 1px solid #f0f0f0;
    button {
      height: 29px !important;
      width: 24px;
      margin-left: 3px;
      .ivu-icon {
        position: absolute;
        right: 3px;
        bottom: 1px;
      }
    }
  }
  .btn-con {
    z-index: 10;
    position: absolute;
    top: 0px;
    height: 100%;
    background: #fff;
    button {
      padding: 3px 0;
      margin-top: 2px;
      margin-left: 4px;
    }

    &.right-btn {
      right: 32px;
      border-right: 1px solid #f0f0f0;
      margin-right: 1px;
      padding-right: 5px;
    }
  }
  .scroll-outer {
    position: absolute;
    left: 27px;
    right: 67px;
    top: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0px 0 3px 2px rgba(100, 100, 100, 0.1) inset;
    .scroll-body {
      height: calc(100% - 1px);
      display: inline-block;
      padding: 1px 4px 0;
      position: absolute;
      overflow: visible;
      white-space: nowrap;
      transition: left 0.3s ease;
      cursor: pointer;
      .ivu-tag-dot-inner {
        transition: background 0.2s ease;
      }
    }
  }
  .contextmenu {
    z-index: 1000;
    position: absolute;
    margin: 0;
    padding: 5px 0;
    background: #fff;
    z-index: 1000;
    list-style-type: none;
    border-radius: 4px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.1);
    li {
      margin: 0;
      padding: 5px 15px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>
