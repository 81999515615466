<template>
  <Submenu :name="`${parentName}`">
    <template v-slot:title>
      <common-icon :type="parentItem.icon || ''" />
      <span>{{ showTitle(parentItem) }}</span>
    </template>
    <template v-for="item in children">
      <template v-if="item.children && item.children.length === 1">
        <side-menu-item
          v-if="showChildren(item)"
          :key="`menu-${item.name}`"
          :parent-item="item"
        ></side-menu-item>
        <menu-item
          v-else
          :name="getNameOrHref(item, true)"
          :key="`menu-${item.children[0].name}`"
          ><common-icon :type="item.children[0].icon || ''" /><span>{{
            showTitle(item.children[0])
          }}</span></menu-item
        >
      </template>
      <template v-else>
        <side-menu-item
          v-if="showChildren(item)"
          :key="`menu-${item.name}`"
          :parent-item="item"
        ></side-menu-item>
        <menu-item
          v-else
          :name="getNameOrHref(item)"
          :key="`menu-${item.name}` + ''"
          ><common-icon :type="item.icon || ''" /><span>{{
            showTitle(item)
          }}</span></menu-item
        >
      </template>
    </template>
  </Submenu>
</template>
<script setup>
import CommonIcon from '@/components/common-icon/common-icon'
import { computed } from '@vue/runtime-core'
import { showTitle, showChildren, getNameOrHref } from './mixin'
const props = defineProps({
  parentItem: {
    type: Object,
    default: () => {}
  },
  theme: String,
  iconSize: Number
})

const parentName = computed(() => {
  return props.parentItem.name
})
const children = computed(() => {
  return props.parentItem.children
})
</script>
