<template>
  <Modal title="提示" :model-value="modelValue" width="22%">
    <div class="center">
      <p class="title">{{ $t('themeColorChange') }}</p>
      <Space size="large" wrap>
        <Space><ColorPicker v-model="mColor" /> </Space>
      </Space>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <Button @click="closed">{{ $t('cancel') }}</Button>
        <Button type="primary" @click="comfirm">{{ $t('confirm') }}</Button>
      </span>
    </template>
  </Modal>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from '@/libs/theme'
defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})
const emits = defineEmits(['update:modelValue'])
const store = useStore()
// 默认色值
const mColor = ref(store.getters['theme/getMainColor'])
/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}
/**
 * 确定
 * 1. 修改主题色
 * 2. 保存最新的主题色
 * 3. 关闭 dialog
 */
const comfirm = async () => {
  // 1.1 获取主题色
  const newStyleText = await generateNewStyle(mColor.value)
  // 1.2 写入最新主题色
  writeNewStyle(newStyleText)
  // 2. 保存最新的主题色
  store.commit('theme/setMainColor', mColor.value)
  // 3. 关闭 dialog
  closed()
}
</script>

<style lang="scss" scoped>
.center {
  text-align: center;
  .title {
    margin-bottom: 12px;
  }
}
</style>
