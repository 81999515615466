<template>
  <div
    class="app-wrapper"
    :class="[
      $store.getters['app/getSidebarOpened'] ? 'openSidebar' : 'hideSidebar'
    ]"
  >
    <!-- 左侧 menu -->
    <sidebar
      id="guide-sidebar"
      class="sidebar-container"
      :class="[
        $store.getters['app/getSidebarOpened']
          ? 'sidebar-container'
          : 'sidebar-container hide-sidebar-container'
      ]"
      :style="{ backgroundColor: $store.getters.cssVar.menuBg }"
    />
    <div class="main-container">
      <div class="fixed-header">
        <!-- 顶部的 navbar -->
        <navbar />
        <tags-nav id="guide-tags"></tags-nav>
      </div>
      <!-- 内容区 -->
      <app-main />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Navbar from './components/navbar'
import TagsNav from './components/tags-nav'
import Sidebar from './components/sidebar/index'
import AppMain from './components/app-main'
import store from '@/store'
const isCollapsed = ref(false)
isCollapsed.value = store.getters['app/getSidebarOpened']
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';
@import '@/styles/variables.module.scss';
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width #{$sideBarDuration};
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSideBarWidth});
}
</style>
