export const TOKEN_KEY = 'token'
export const REFRESH_TOKEN_KEY = 'refreshToken'
// 国际化
export const LANG = 'language'
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#2d8cf0'
// 路由标签
export const TAG_VIEW = 'tagNaveList'
// 动态路由
export const ROUTES = 'routes'
// 角色菜单缓存
export const MENU_DATA = 'menuData'
// 角色权限缓存
export const TABLE_DATA = 'tableData'
// 权限缓存
export const ACCESS = 'access'
