import { login, logout } from '@/api/login'
import { getUserInfo } from '@/api/user'
import { ACCESS, MENU_DATA, ROUTES, TABLE_DATA } from '@/constant'
import {
  setToken,
  getToken,
  setRefreshToken,
  getRefreshToken,
  localRead,
  localSave,
  setTagNavListInLocalstorage
} from '@/libs/util'
export default {
  namespaced: true,
  state: {
    userDetail: localRead('userDetail') || {},
    token: getToken(),
    refreshToken: getRefreshToken(),
    access: localRead(ACCESS),
    // 动态路由
    routes: localRead(ROUTES) || [],
    // 判断是否刷新
    isRefresh: true
  },
  getters: {
    getUserDetail: (state) => state.userDetail,
    getToken: (state) => state.token,
    hasUserInfo: (state) => {
      return JSON.stringify(state.userDetail) !== '{}'
    },
    getAsscess: (state) => {
      return state.access
    },
    getRoutes: (state) => state.routes,
    getIsRefresh: (state) => state.isRefresh
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
      setRefreshToken(refreshToken)
    },
    setUserDetail(state, userDetail) {
      state.userDetail = userDetail
      localSave('userDetail', userDetail)
    },
    setAccess(state, access) {
      state.access = access
      localSave(ACCESS, access)
    },
    setRoutes(state, routes) {
      localSave(ROUTES, routes)
      state.isRefresh = false
      state.routes = routes
    },
    setIsRefresh(state, isRefresh) {
      state.isRefresh = isRefresh
    }
  },
  actions: {
    // 登录
    handleLogin(store, payload) {
      return new Promise((resolve, reject) => {
        login({
          ...payload
        })
          .then((res) => {
            if ('accessToken' in res) {
              const userDetail = res.userDetails
              store.commit('setToken', res.accessToken)
              store.commit('setRefreshToken', res.refreshToken)
              store.commit('setUserDetail', userDetail)
              store.commit(
                'setAccess',
                userDetail.authorities.map((item) => item.authority)
              )
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 退出登录
    handleLogOut(store, payload) {
      return new Promise((resolve, reject) => {
        logout(store.state.token)
          .then(() => {
            store.commit('setToken', '')
            store.commit('setRefreshToken', '')
            store.commit('setUserDetail', {})
            store.commit('setAccess', [])
            store.commit('setRoutes', [])
            localStorage.removeItem(MENU_DATA)
            localStorage.removeItem(TABLE_DATA)
            setTagNavListInLocalstorage([])
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 获取用户相关信息
    getUserDetail(store, payload) {
      return new Promise((resolve, reject) => {
        try {
          getUserInfo(store.state.token)
            .then((res) => {
              store.commit('setUserDetail', res)
              store.commit(
                'setAccess',
                res.authorities.map((item) => item.authority)
              )
              resolve(res)
            })
            .catch((err) => {
              reject(err)
            })
        } catch (error) {
          reject(error)
        }
      })
    }
  }
}
