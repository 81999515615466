import router from '@/router'
import { LoadingBar } from 'view-ui-plus'
import store from '@/store'
import config from '@/config'
import { filterRoutes, getRoutesName, setTitle } from '@/libs/util'
import communityRoutes from '@/router/modules/community'
router.beforeEach(async (to, from, next) => {
  LoadingBar.start()
  // 存在 token ，进入主页
  if (store.getters['user/getToken']) {
    // 如果登录状态进入login则进入首页
    if (to.path === '/login') {
      next('/home')
    } else {
      const routesData = store.getters['user/getRoutes']
      const routesName = getRoutesName(routesData)
      const newRoutes = filterRoutes(communityRoutes, routesName)
      newRoutes.forEach((item) => {
        router.addRoute(item)
      })
      if (store.getters['user/getIsRefresh']) {
        store.commit('user/setIsRefresh', false)
        return next(to.path)
      } else {
        next()
      }
    }
  } else {
    // 没有token的情况下，查看是否在白名单
    let check = false
    config.publicPath.forEach((item) => {
      if (item.test(to.path)) {
        check = true
      }
    })
    if (check) {
      next()
    } else {
      next({
        name: 'login'
      })
    }
  }
})

router.afterEach((to) => {
  setTitle(to)
  LoadingBar.finish()
  window.scrollTo(0, 0)
})
