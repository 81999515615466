import { request, fileRequest, downloadRequest } from '@/libs/request'
import axios from 'axios'
import config from '@/config'

class Dispatch {
  #h
  #j
  #u
  constructor(url) {
    this.url = url
    this.#h = (key, dataset, method) => {
      const args = []
      const url = this.url[key]
      if (!url) {
        throw new Error('参数不正确，请检查custom.js中定义')
      }
      args.push(url)
      args.push(method)
      args.push(dataset)
      return request.apply(this, args)
    }
    this.#j = (key, dataset, method) => {
      const args = []
      const url = this.url[key]
      if (!url) {
        throw new Error('参数不正确，请检查custom.js中定义')
      }
      args.push(url)
      args.push(method)
      args.push(dataset)
      return downloadRequest.apply(this, args)
    }
    this.#u = (key, dataset, method) => {
      const args = []
      const url = this.url[key]
      if (!url) {
        throw new Error('参数不正确，请检查custom.js中定义')
      }
      args.push(url)
      args.push(method)
      args.push(dataset)
      return fileRequest.apply(this, args)
    }
  }

  get(key, dataset) {
    if (config.mockEnable) {
      return this.mockRequest(key, 'get')
    } else {
      return this.#h(key, dataset, 'get')
    }
  }

  getAndJoint(key, param) {
    if (config.mockEnable) {
      return this.mockRequest(key, 'get')
    } else {
      const args = []
      const url = this.url[key]
      if (!url) {
        throw new Error('参数不正确，请检查custom.js中定义')
      }
      args.push(url + '/' + param)
      args.push('get')
      return request.apply(this, args)
    }
  }

  postAndJoint(key, param) {
    if (config.mockEnable) {
      return this.mockRequest(key, 'post')
    } else {
      const args = []
      const url = this.url[key]
      if (!url) {
        throw new Error('参数不正确，请检查custom.js中定义')
      }
      args.push(url + '/' + param)
      args.push('post')
      return request.apply(this, args)
    }
  }

  post(key, dataset) {
    if (config.mockEnable) {
      return this.mockRequest(key, 'post')
    } else {
      return this.#h(key, dataset, 'post')
    }
  }

  upload(key, formData) {
    return this.#u(key, formData, 'post')
  }

  download(key, dataset) {
    return this.#j(key, dataset, 'get')
  }

  put(key, dataset) {
    if (config.mockEnable) {
      return this.mockRequest(key, 'put')
    } else {
      return this.#h(key, dataset, 'put')
    }
  }

  delete(key, dataset) {
    if (config.mockEnable) {
      return this.mockRequest(key, 'delete')
    } else {
      return this.#h(key, dataset, 'delete')
    }
  }

  getUrl(key) {
    return this.url[key] === undefined ? '' : this.url[key]
  }

  // 下载文件
  down(data, fileName) {
    if (!data) {
      return
    }
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    // 释放URL对象所占资源
    window.URL.revokeObjectURL(url)
    // 用完即删
    document.body.removeChild(link)
  }

  mockRequest(key, method) {
    axios.interceptors.response.use(
      function (response) {
        // 对响应数据做点什么
        if (response && 'data' in response) {
          response = response.data
        }
        return response
      },
      function (error) {
        // 对响应错误做点什么
        return Promise.reject(error)
      }
    )
    return axios.request({
      url: this.url[key],
      method: method
    })
  }
}

export default Dispatch
