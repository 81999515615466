import HttpRequest from '@/libs/axios'
import config from '@/config'
import { getToken } from '@/libs/util'
import qs from 'qs'
const baseUrl =
  process.env.NODE_ENV === 'development'
    ? config.baseUrl.dev
    : config.baseUrl.pro

const axios = new HttpRequest(baseUrl)

const request = (url, method, dataset = {}) => {
  const res = axios.request({
    url: url,
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'Content-Type': 'application/json',
      'cached-control': 'no-cache'
    },
    method: method,
    [method === 'get' ? 'params' : 'data']: dataset,
    paramsSerializer: {
      serialize: function (params) {
        return qs.stringify(params, { arrayFormat: 'indices' })
      }
    }
  })
  return res
}

const fileRequest = (url, method, dataset = {}) => {
  return axios.request({
    url: url,
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'Content-Type': 'multipart/form-data',
      'cached-control': 'no-cache'
    },
    method: method,
    [method === 'get' ? 'params' : 'data']: dataset,
    paramsSerializer: {
      serialize: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  })
}

const downloadRequest = (url, method, dataset = {}) => {
  return axios.request({
    responseType: 'blob',
    url: url,
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'Content-Type': 'multipart/form-data',
      'cached-control': 'no-cache'
    },
    method: method,
    [method === 'get' ? 'params' : 'data']: dataset,
    paramsSerializer: {
      serialize: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  })
}

export { axios, request, fileRequest, downloadRequest }
