<template>
  <Sider
    ref="sider"
    hide-trigger
    collapsible
    :collapsed-width="54"
    v-model="isCollapsed"
    class="left-sider"
    :style="{ overflow: 'hidden' }"
  >
    <Menu
      ref="menu"
      v-show="!isCollapsed"
      :active-name="$route.name"
      :open-names="openedNames"
      accordion
      width="auto"
      @on-select="turnToPage"
      :style="'background:' + $store.getters.cssVar.menuBg"
    >
      <template v-for="item in menuList">
        <template v-if="item.children && item.children.length === 1">
          <side-menu-item
            v-if="showChildren(item)"
            :key="`menu-${item.name}`"
            :parent-item="item"
          ></side-menu-item>
          <menu-item
            v-else
            :name="getNameOrHref(item, true)"
            :key="`menu-${item.children[0].name}`"
            ><common-icon :type="item.children[0].icon || ''" /><span>{{
              showTitle(item.children[0])
            }}</span></menu-item
          >
        </template>
        <template v-else>
          <side-menu-item
            v-if="showChildren(item)"
            :key="`menu-${item.name}`"
            :parent-item="item"
          ></side-menu-item>
          <menu-item
            v-else
            :name="getNameOrHref(item)"
            :key="`menu-${item.name}` + ''"
            ><common-icon :type="item.icon || ''" /><span>{{
              showTitle(item)
            }}</span></menu-item
          >
        </template>
      </template>
    </Menu>
    <div
      class="menu-collapsed"
      :style="isCollapsed ? 'background:' + $store.getters.cssVar.menuBg : ''"
      v-show="isCollapsed"
      :list="menuList"
    >
      <template v-for="item in menuList">
        <collapsed-menu
          v-if="item.children && item.children.length > 1"
          @on-click="turnToPage"
          hide-title
          :parent-item="item"
          :key="`drop-menu-${item.name}`"
        ></collapsed-menu>
        <Tooltip
          transfer
          v-else
          :content="
            showTitle(
              item.children && item.children[0] ? item.children[0] : item
            )
          "
          placement="right"
          :key="`drop-menu-${item.name}` + ''"
        >
          <a
            @click="handleSelect(getNameOrHref(item, true))"
            class="drop-menu-a"
            :style="{ textAlign: 'center' }"
            ><common-icon
              :color="$store.getters['cssVar'].menuText"
              :type="item.icon || (item.children && item.children[0].icon)"
          /></a>
        </Tooltip>
      </template>
    </div>
  </Sider>
</template>
<script setup>
import CommonIcon from '@/components/common-icon/common-icon'
import CollapsedMenu from './collapsed-menu'
import { showTitle, showChildren, getNameOrHref } from './mixin'
import { computed, nextTick, ref, watch } from 'vue'
import store from '@/store'
import router from '@/router'
import SideMenuItem from './side-menu-item'
import { getMenuByRouter, filterRoutes, getRoutesName } from '@/libs/util'
import { useRoute } from 'vue-router'
import { getUnion } from '@/libs/tools'

import communityRoutes from '@/router/modules/community'
import routes from '@/router/routes'
const openedNames = ref([])
const isCollapsed = ref(false)
const sider = ref(null)
isCollapsed.value = !store.getters['app/getSidebarOpened']
watch(
  () => store.getters['app/getSidebarOpened'],
  (newValue) => {
    if (sider.value) {
      sider.value.toggleCollapse()
    }
  }
)
const route = useRoute()
const getOpenedNamesByActiveName = (name) => {
  return route.matched.map((item) => item.name).filter((item) => item !== name)
}
openedNames.value = getUnion(openedNames.value, getOpenedNamesByActiveName())
watch(
  () => route.name,
  (name) => {
    openedNames.value = getOpenedNamesByActiveName(name)
  }
)
const menu = ref(null)
watch(openedNames, () => {
  nextTick(() => {
    menu.value.updateOpened()
  })
})

const turnToPage = (route) => {
  let { name, params, query } = {}
  if (typeof route === 'string') name = route
  else {
    name = route.name
    params = route.params
    query = route.query
  }
  if (name.indexOf('isTurnByHref_') > -1) {
    window.open(name.split('_')[1])
    return
  }

  router.push({
    name,
    params,
    query
  })
}
const menuList = computed(() => {
  const routesData = store.getters['user/getRoutes']
  const routesName = getRoutesName(routesData)
  const newRoutes = filterRoutes(communityRoutes, routesName)
  return getMenuByRouter(newRoutes.concat(routes))
})

const handleSelect = (route) => {
  if (route.indexOf('isTurnByHref_') > -1) {
    window.open(route.split('_')[1])
  } else {
    router.push({ name: route })
  }
}
</script>

<style lang="scss" scoped></style>
