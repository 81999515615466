<template>
  <div>
    <Icon
      id="guide-start"
      size="20"
      custom="iconfont i-guide"
      @click="onClick"
    ></Icon>
  </div>
</template>

<script setup>
import { onMounted, watch } from '@vue/runtime-core'
import Driver from 'driver.js'
import { useI18n } from 'vue-i18n'
import steps from './steps'
import 'driver.js/dist/driver.min.css'
import { useStore } from 'vuex'
const i18n = useI18n()
let driver = null
onMounted(() => {
  driver = new Driver({
    // 禁止点击蒙版关闭
    allowClose: false,
    closeBtnText: i18n.t('close'),
    nextBtnText: i18n.t('next'),
    prevBtnText: i18n.t('prev')
  })
})
const onClick = () => {
  driver.defineSteps(steps(i18n))
  driver.start()
}
const store = useStore()
watch(
  () => store.getters['app/getLocal'],
  () => {
    driver = new Driver({
      // 禁止点击蒙版关闭
      allowClose: false,
      closeBtnText: i18n.t('close'),
      nextBtnText: i18n.t('next'),
      prevBtnText: i18n.t('prev')
    })
  },
  { immediate: true, deep: true }
)
</script>

<style scoped></style>
