import color from 'css-color-function'
import rgbHex from 'rgb-hex'
import formula from '@/constant/formula.json'
import axios from 'axios'

/**
 * 写入新样式到 style
 * @param {*} elNewStyle  view-ui-plus 的新样式
 * @param {*} isNewStyleTag 是否生成新的 style 标签
 */
export const writeNewStyle = (elNewStyle) => {
  const style = document.createElement('style')
  style.innerText = elNewStyle
  document.head.appendChild(style)
}

/**
 * 根据主色值，生成最新的样式表
 */
export const generateNewStyle = async (primaryColor) => {
  const colors = generateColors(primaryColor)
  let cssText = await getOriginalStyle()

  // 遍历生成的样式表，在 CSS 的原样式中进行全局替换
  Object.keys(colors).forEach((key) => {
    cssText = cssText.replace(
      new RegExp('(:|\\s+)' + key, 'g'),
      '$1' + colors[key]
    )
  })

  return cssText
}

/**
 * 根据主色生成色值表
 */
export const generateColors = (primary) => {
  if (!primary) return
  const colors = {
    primary
  }
  Object.keys(formula).forEach((key) => {
    const value = formula[key].replace(/primary/g, primary)
    colors[key] = '#' + rgbHex(color.convert(value))
  })
  return colors
}

/**
 * 获取当前 view-ui-plus 的默认样式表
 */
const getOriginalStyle = async () => {
  const version = require('view-ui-plus/package.json').version
  const url = `https://unpkg.com/view-ui-plus@${version}/dist/styles/viewuiplus.css`
  const { data } = await axios(url)
  // 把获取到的数据筛选为原样式模板
  return getStyleTemplate(data)
}

/**
 * 返回 style 的 template
 */
const getStyleTemplate = (data) => {
  // element-plus 默认色值
  const colorMap = {
    '#2d8cf0': 'primary'
  }
  // 根据默认色值为要替换的色值打上标记
  Object.keys(colorMap).forEach((key) => {
    const value = colorMap[key]
    data = data.replace(new RegExp(key, 'ig'), value)
  })
  const iconfont =
    '@font-face{font-family:Ionicons;src:url(fonts/ionicons.woff2?v=3.0.0) format("woff2"),url(fonts/ionicons.woff?v=3.0.0) format("woff"),url(fonts/ionicons.ttf?v=3.0.0) format("truetype"),url(fonts/ionicons.svg?v=3.0.0#Ionicons) format("svg");font-weight:400;font-style:normal}'
  data = data.replace(iconfont, '')
  return data
}
