<template>
  <div class="hamburger-container" @click="toggleClick">
    <Icon
      id="guide-hamburger"
      class="hamburger"
      size="20"
      :custom="icon"
    ></Icon>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const toggleClick = () => {
  store.commit('app/triggerSidebarOpened')
}

const icon = computed(() =>
  store.getters['app/getSidebarOpened']
    ? 'iconfont i-flod'
    : 'iconfont i-unflod'
)
</script>

<style lang="scss" scoped>
.hamburger-container {
  padding: 0 16px;
  .hamburger {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
}
</style>
