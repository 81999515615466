import {
  getBreadCrumbList,
  getHomeRoute,
  localRead,
  localSave,
  routeEqual,
  getNextRoute,
  setTagNavListInLocalstorage,
  getTagNavListFromLocalstorage,
  getRouteTitleHandled,
  routeHasExist
} from '@/libs/util'
import config from '@/config/index'
import router from '@/router'
import { LANG, TAG_VIEW } from '@/constant'
const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route)
  state.tagNavList = state.tagNavList.filter((item) => {
    return !routeEqual(item, route)
  })
  router.push(nextRoute)
}
export default {
  namespaced: true,
  state: {
    // 是否伸展sidebar
    sidebarOpened: true,
    // 首页路由
    homeRoute: {},
    // 面包屑
    breadCrumbList: [],
    // 路由标签
    tagNavList: localRead(TAG_VIEW) || [],
    // 国际化
    local: localRead(LANG) || 'zh-CN'
  },
  getters: {
    getSidebarOpened: (state) => state.sidebarOpened,
    getBreadCrumbList: (state) => state.breadCrumbList,
    getLocal: (state) => state.local
  },
  mutations: {
    triggerSidebarOpened(state) {
      state.sidebarOpened = !state.sidebarOpened
    },
    setBreadCrumbList(state, route) {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
    },
    setHomeRoute(state, routes) {
      state.homeRoute = getHomeRoute(routes, config.homeName)
    },
    setLocal(state, lang) {
      localSave(LANG, lang)
      state.local = lang
    },

    setTagNavList(state, list) {
      let tagList = []
      if (list) {
        tagList = [...list]
      } else tagList = getTagNavListFromLocalstorage() || []
      if (tagList[0] && tagList[0].name !== config.homeName) tagList.shift()
      const homeTagIndex = tagList.findIndex(
        (item) => item.name === config.homeName
      )
      if (homeTagIndex > 0) {
        const homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }
      state.tagNavList = tagList
      setTagNavListInLocalstorage([...tagList])
    },
    closeTag(state, route) {
      const tag = state.tagNavList.filter((item) => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      closePage(state, route)
    },
    addTag(state, { route, type = 'unshift' }) {
      const router = getRouteTitleHandled(route)
      if (!routeHasExist(state.tagNavList, router)) {
        if (type === 'push') state.tagNavList.push(router)
        else {
          if (router.name === config.homeName) state.tagNavList.unshift(router)
          else state.tagNavList.splice(1, 0, router)
        }
        setTagNavListInLocalstorage([...state.tagNavList])
      }
    }
  },
  actions: {}
}
