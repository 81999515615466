<template>
  <Dropdown
    ref="dropdown"
    @on-click="handleClick"
    :class="hideTitle ? '' : 'collased-menu-dropdown'"
    :transfer="hideTitle"
    :placement="placement"
  >
    <a
      class="drop-menu-a"
      type="text"
      @mouseover="handleMousemove($event, children)"
      :style="{ textAlign: !hideTitle ? 'left' : '' }"
      ><common-icon
        :size="rootIconSize"
        :color="$store.getters['cssVar'].menuText"
        :type="parentItem.icon" /><span class="menu-title" v-if="!hideTitle">{{
        showTitle(parentItem)
      }}</span
      ><Icon
        style="float: right"
        v-if="!hideTitle"
        type="ios-arrow-forward"
        :size="16"
    /></a>
    <template #list>
      <DropdownMenu ref="dropdown">
        <template v-for="child in children">
          <collapsed-menu
            v-if="showChildren(child)"
            :icon-size="iconSize"
            :parent-item="child"
            :key="`drop-${child.name}`"
          ></collapsed-menu>
          <DropdownItem
            v-else
            :key="`drop-${child.name}` + ''"
            :name="child.name"
            ><common-icon :size="iconSize" :type="child.icon" /><span
              class="menu-title"
              >{{ showTitle(child) }}</span
            ></DropdownItem
          >
        </template>
      </DropdownMenu>
    </template>
  </Dropdown>
</template>
<script setup>
import CommonIcon from '@/components/common-icon/common-icon'
import { findNodeUpperByClasses } from '@/libs/util'
import { ref } from '@vue/reactivity'
import { computed, getCurrentInstance, onMounted } from '@vue/runtime-core'
import { showTitle, showChildren } from './mixin'
const props = defineProps({
  hideTitle: {
    type: Boolean,
    default: false
  },
  rootIconSize: {
    type: Number,
    default: 16
  },
  parentItem: {
    type: Object,
    default: () => {}
  },
  theme: String,
  iconSize: Number
})
const children = computed(() => {
  return props.parentItem.children
})
onMounted(() => {
  const { proxy } = getCurrentInstance()
  const d = findNodeUpperByClasses(proxy.$refs.dropdown.$el, [
    'ivu-select-dropdown',
    'ivu-dropdown-transfer'
  ])
  if (d) d.style.overflow = 'visible'
})
const placement = ref('right-end')
const emit = defineEmits(['on-click'])
const handleClick = (name) => {
  emit('on-click', name)
}
const handleMousemove = (event, children) => {
  const { pageY } = event
  const height = children.length * 38
  const isOverflow = pageY + height < window.innerHeight
  placement.value = isOverflow ? 'right-start' : 'right-end'
}
</script>
<style lang="scss" scoped></style>
