import Mock from 'mockjs'
import config from '@/config'
import * as area from './modules/area'
// 1. 开启/关闭[所有模块]拦截, 通过调[openMock参数]设置.
// 2. 开启/关闭[业务模块]拦截, 通过调用fnCreate方法[isOpen参数]设置.
// 3. 开启/关闭[业务模块中某个请求]拦截, 通过函数返回对象中的[isOpen属性]设置.
const openMock = config.mockEnable
// let openMock = false
fnCreate(area, openMock)
/**
 * 创建mock模拟数据
 * @param {*} mod 模块
 * @param {*} isOpen 是否开启?
 */
function fnCreate(mod, isOpen = true) {
  if (isOpen) {
    for (const key in mod) {
      ;((res) => {
        Mock.mock(res.url, res.type, (opts) => {
          opts.data = opts.body ? JSON.parse(opts.body) : null
          console.log('\n')
          console.log('%cmock拦截, 请求: ', 'color:blue', opts)
          console.log('%cmock拦截, 响应: ', 'color:blue', res.data)
          return res.data
        })
      })(mod[key]() || {})
    }
  }
}
