import { createStore } from 'vuex'
import user from './module/user'
import app from './module/app'
import theme from './module/theme'
import { generateColors } from '@/libs/theme'
import { localRead } from '@/libs/util'
import { MAIN_COLOR } from '@/constant'

export default createStore({
  state: {
    sid: ''
  },
  getters: {
    cssVar: (state) => {
      return {
        ...state.theme.variables,
        ...generateColors(localRead(MAIN_COLOR))
      }
    }
  },
  mutations: {
    setSid(state, payload) {
      state.sid = payload
    }
  },
  actions: {},
  modules: {
    user,
    app,
    theme
  }
})
