<template>
  <Breadcrumb class="breadcrumb">
    <transition-group name="breadcrumb">
      <BreadcrumbItem
        v-for="item in breadCrumbList"
        :to="item.to"
        :key="`bread-crumb-${item.name}`"
      >
        <common-icon style="margin-right: 4px" :type="item.icon || ''" />
        {{
          showTitle(item).length > 30
            ? showTitle(item).substring(0, 30) + '...'
            : showTitle(item)
        }}
      </BreadcrumbItem>
    </transition-group>
  </Breadcrumb>
</template>
<script setup>
import commonIcon from '@/components/common-icon/common-icon'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import routes from '@/router/routes'
import { showTitle as st } from '@/libs/util'
const store = useStore()
const route = useRoute()
// 生成数组数据
const breadCrumbList = computed(() => {
  return store.getters['app/getBreadCrumbList']
})
store.commit('app/setHomeRoute', routes)
// 监听路由变化时触发
watch(
  route,
  (newRoute) => {
    store.commit('app/setBreadCrumbList', newRoute)
  },
  {
    immediate: true
  }
)

// 将来需要进行主题替换，所以这里获取下动态样式
// eslint-disable-next-line
const linkHoverColor = ref(store.getters['cssVar'].menuBg)

const showTitle = (item) => {
  return st(item, this)
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  :deep(.no-redirect) {
    color: #97a8be;
    cursor: text;
  }
  .redirect {
    color: #666;
    font-weight: 600;
  }

  .redirect:hover {
    // 将来需要进行主题替换，所以这里不去写死样式
    color: v-bind(linkHoverColor);
  }
}
</style>
