<template>
  <router-view v-if="isRouterAlive" />
</template>

<script setup>
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from '@/libs/theme'
const { nextTick, ref, provide } = require('@vue/runtime-core')
// 加载主题色
const store = useStore()
generateNewStyle(store.getters['theme/getMainColor']).then((newStyleText) => {
  writeNewStyle(newStyleText)
})
// 加载刷新页面方法
const isRouterAlive = ref(true)
const reload = () => {
  isRouterAlive.value = false
  nextTick(() => {
    isRouterAlive.value = true
  })
}
provide('reload', reload)
</script>

<style lang="scss" scoped></style>
