<template>
  <Dropdown class="international" @on-click="handleSetLanguage">
    <div>
      <Icon id="guide-lang" size="20" custom="iconfont i-language"></Icon>
    </div>
    <template #list>
      <DropdownMenu>
        <DropdownItem :disabled="language === 'zh-CN'" name="zh-CN">
          中文简体
        </DropdownItem>
        <DropdownItem :disabled="language === 'zh-TW'" name="zh-TW">
          中文繁体
        </DropdownItem>
        <DropdownItem :disabled="language === 'en-US'" name="en-US">
          English
        </DropdownItem>
      </DropdownMenu>
    </template>
  </Dropdown>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { Icon, Message } from 'view-ui-plus'
import { localSave } from '@/libs/util'
import { LANG } from '@/constant'
const props = defineProps({
  isClearForm: {
    type: Boolean,
    default: false
  }
})
const store = useStore()
const language = computed(() => {
  return store.getters['app/getLocal']
})
const emit = defineEmits(['on-clear'])
// 切换语言的方法
const i18n = useI18n()
const handleSetLanguage = (lang) => {
  i18n.locale.value = lang
  store.commit('app/setLocal', lang)
  localSave(LANG, lang)
  Message.success(i18n.t('selectLanguage'))
  if (props.isClearForm) {
    emit('on-clear')
  }
}
</script>
