import directive from './directives'

const importDirective = (app) => {
  /**
   * 拖拽指令 v-draggable="options"
   * options = {
   *  trigger: /这里传入作为拖拽触发器的CSS选择器/,
   *  body:    /这里传入需要移动容器的CSS选择器/,
   *  recover: /拖动结束之后是否恢复到原来的位置/
   * }
   */
  app.directive('draggable', directive.draggable)
  /**
   * clipboard指令 v-draggable="options"
   * options = {
   *  value:    /在输入框中使用v-model绑定的值/,
   *  success:  /复制成功后的回调/,
   *  error:    /复制失败后的回调/
   * }
   */
  app.directive('clipboard', directive.clipboard)
  /**
   * 角色权限指令 v-hasRole="'sys:role:save'"
   */
  app.directive('hasRole', directive.permission.hasRole)
  /**
   * 角色权限指令 v-hasPermission="'sys:role:save'"
   */
  app.directive('hasPermission', directive.permission.hasPermission)
  /**
   * 角色权限指令 v-hasPermission="['1','2']"
   */
  app.directive('hasPermissions', directive.permission.hasPermissions)
}

export default importDirective
