import store from '@/store'

export default {
  /** 角色 '' */
  hasRole: {
    mounted: function (el, binding, vnode) {
      const roles = store.state.user.access || []
      if (!roles.includes(binding.value)) {
        // 删除元素
        el.parentNode.removeChild(el)
      }
    }
  },
  /** 资源点 '' */
  hasPermission: {
    mounted: function (el, binding, vnode) {
      const roles = store.state.user.access || []
      if (!roles.includes(binding.value)) {
        // 禁用按钮
        el.disabled = true
      }
    }
  },
  /** 多个权限点 ['1','2'] */
  hasPermissions: {
    mounted: function (el, binding, vnode) {
      const roles = store.state.user.access || []
      const values = binding.value
      let flag = true
      for (const v of values) {
        if (!roles.includes(v)) {
          flag = false
        }
      }
      if (!flag) {
        el.parentNode.removeChild(el)
      }
    }
  }
}
